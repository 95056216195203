import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Header } from './components/Header';
import { BenchmarkList } from './components/BenchmarkList';
import { Benchmark } from './components/Benchmark';

import './reset.css';
import './App.css';

export const App = () => {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <div className="App">
          <Header />
          <Route path="/">
            <Switch>
              <Route path="/benchmark/:id" component={Benchmark} />
              <Route path="/" component={BenchmarkList} />
            </Switch>
          </Route>
        </div>
      </BrowserRouter>
    </React.StrictMode>
  );
};
