export const displayNumber = (number: number, { precision, unit }: { precision?: number; unit?: string } = {}) => {
  let displayedNumber = number;
  if (displayedNumber) {
    if (precision) {
      displayedNumber = Math.round(number * Math.pow(10, precision)) / Math.pow(10, precision);
    }
    return `${displayedNumber}${unit ? ` ${unit}` : ''}`;
  }
  return '';
};
