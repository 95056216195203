import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { getModel, ModelApiResponse } from '../../api';
import { BenchmarkGraph } from '../BenchmarkGraph';
import { BenchmarkSummary } from '../BenchmarkSummary';
import { Loading } from '../Loading';

import './Benchmark.css';

const formatGraphData = (data: { createdAt: string; time: number; hardware: { type: string } }) => {
  if (data.hardware.type === 'gpu') {
    return {
      date: dayjs(data.createdAt).toDate().getTime(),
      timeGPU: data.time,
    };
  }
  return {
    date: dayjs(data.createdAt).toDate().getTime(),
    timeCPU: data.time,
  };
};

type GraphBenchmarkData = {
  timeCPU?: number | null;
  timeGPU?: number | null;
  date: number;
};

export type BenchmarkProps = RouteComponentProps<{ id: string }>;

export const Benchmark = ({ match }: BenchmarkProps) => {
  const [model, setModel] = useState<ModelApiResponse | null>(null);
  const [compilationBenchmarkData, setCompilationBenchmarkData] = useState<GraphBenchmarkData[] | null>(null);
  const [rteBenchmarkData, setRTEBenchmarkData] = useState<GraphBenchmarkData[] | null>(null);
  const fetchData = () => {
    getModel(+match.params.id).then((response) => {
      setModel(response);
      console.log(response);
    });
  };

  useEffect(fetchData, [match.params.id]);
  useEffect(() => {
    if (!model) {
      setRTEBenchmarkData(null);
      setCompilationBenchmarkData(null);
      return;
    }
    const compilationData: GraphBenchmarkData[] = model.compilationBenchmarks
      .map(formatGraphData)
      .filter((v) => v.timeCPU || v.timeGPU)
      .sort((a, b) => {
        if (a.date > b.date) return 1;
        if (a.date < b.date) return -1;
        return 0;
      });
    setCompilationBenchmarkData(compilationData);

    const rteData: GraphBenchmarkData[] = model.compilationBenchmarks
      .reduce((rteBenchmarks, compilationBenchmark) => {
        rteBenchmarks.push(...compilationBenchmark.rteBenchmarks);
        return rteBenchmarks;
      }, [])
      .map(formatGraphData)
      .filter((v: GraphBenchmarkData) => v.timeCPU || v.timeGPU)
      .sort((a: any, b: any) => {
        if (a.date > b.date) return 1;
        if (a.date < b.date) return -1;
        return 0;
      });
    setRTEBenchmarkData(rteData);
  }, [model]);

  return (
    <div className="Benchmark__container">
      <p>
        <Link to="/">&lt; Back</Link>
      </p>
      {!model && (
        <div className="Benchmark__loading">
          <Loading size="large" />
        </div>
      )}
      {model && <BenchmarkSummary model={model} />}
      {compilationBenchmarkData && (
        <BenchmarkGraph
          title="Compilation"
          data={compilationBenchmarkData}
          lines={[{ dataKey: 'timeCPU' }, { dataKey: 'timeGPU', stroke: '#82ca9d' }]}
        />
      )}
      {rteBenchmarkData && (
        <BenchmarkGraph
          title="RTE"
          data={rteBenchmarkData}
          lines={[{ dataKey: 'timeCPU' }, { dataKey: 'timeGPU', stroke: '#82ca9d' }]}
        />
      )}
    </div>
  );
};
