import React from 'react';
import loadingSVG from './loading.svg';

export type LoadingProps = {
  size?: 'small' | 'normal' | 'large';
};

export const Loading = ({ size = 'normal' }: LoadingProps) => {
  let width: number;
  switch (size) {
    case 'small': {
      width = 30;
      break;
    }
    case 'large': {
      width = 100;
      break;
    }
    case 'normal':
    default: {
      width = 50;
    }
  }
  return <img src={loadingSVG} alt="Loading" width={width} />;
};
