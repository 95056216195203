import dayjs from 'dayjs';
import React from 'react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { displayNumber } from '../../utils/formatData';

import './BenchmarkGraph.css';

const formatXAxis = (tickItem: string) => {
  return dayjs(tickItem).format('L');
};

const formatter = (value: string, name: string) => {
  const label = name === 'timeGPU' ? 'GPU Time' : 'CPU Time';
  return [displayNumber(+value, { precision: 2, unit: 's' }), label];
};
const labelFormatter = (label: string) => dayjs(label).format('HH:mm:ss L');

export type BenchmarkGraphProps = {
  title: string;
  data: any[];
  lines: { dataKey: string; [key: string]: any }[];
};

export const BenchmarkGraph = ({ title, data, lines }: BenchmarkGraphProps) => {
  return (
    <div className="BenchmarkGraph__graph">
      <h3 className="BenchmarkGraph__title">{title}</h3>

      {!!data.length && (
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={data}>
            <XAxis dataKey="date" tickFormatter={formatXAxis} />
            <YAxis />
            <Tooltip labelFormatter={labelFormatter} formatter={formatter} />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            {lines.map((p) => (
              <Line key={p.dataKey} type="monotone" stroke="#8884d8" {...p} />
            ))}
          </LineChart>
        </ResponsiveContainer>
      )}
      {!data.length && <div className="BenchmarkGraph__nodata">No data</div>}
    </div>
  );
};
