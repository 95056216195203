import React from 'react';
import Table, { AutoResizer, Column, BaseTableProps } from 'react-base-table';
import { BenchmarkListHeader } from './BenchmarkListHeader';
import { displayNumber } from '../../utils/formatData';
import { StatusIcon } from '../StatusIcon';

import './BenchmarkTable.css';
import { ModelListItem } from '../../api';
import { NameCell } from './NameCell';
import { PublicIcon } from '../PublicIcon';
import dayjs from 'dayjs';

export type BenchmarkTree = Pick<ModelListItem, 'name'> & { id: string; children: ModelListItem[] };

export const BenchmarkTable = ({
  data,
  sortBy,
  onColumnSort,
}: {
  data: BenchmarkTree[];
  sortBy: BaseTableProps<BenchmarkTree>['sortBy'];
  onColumnSort: BaseTableProps<BenchmarkTree>['onColumnSort'];
}) => {
  return (
    <AutoResizer>
      {({ width, height }) => (
        <Table
          data={data}
          width={width}
          height={height}
          fixed
          headerHeight={[40, 50]}
          headerRenderer={BenchmarkListHeader}
          expandColumnKey="name"
          expandedRowKeys={data.map((d) => d.id)}
          sortBy={sortBy}
          onColumnSort={onColumnSort}
        >
          <Column
            key="name"
            dataKey="name"
            width={250}
            title="Name"
            cellRenderer={NameCell}
            frozen="left"
            category="General"
            sortable
          />
          <Column
            key="internal"
            dataKey="internal"
            frozen="left"
            width={80}
            align="center"
            cellRenderer={({ cellData }) => <PublicIcon internal={cellData} />}
            title="Internal"
            category="General"
            sortable
          />
          <Column
            key="inferenceBenchmarks.cpu.time"
            dataKey="inferenceBenchmarks.cpu.time"
            cellRenderer={({ cellData }) => displayNumber(cellData, { precision: 2, unit: 'μs' })}
            width={100}
            align="center"
            title="CPU"
            category="Inference"
            sortable
          />
          <Column
            key="inferenceBenchmarks.gpu.time"
            dataKey="inferenceBenchmarks.gpu.time"
            cellRenderer={({ cellData }) => displayNumber(cellData, { precision: 2, unit: 'μs' })}
            width={150}
            align="center"
            title="GPU"
            category="Inference"
            sortable
          />
          <Column
            key="rteBenchmarks.cpu.status"
            dataKey="rteBenchmarks.cpu.status"
            cellRenderer={StatusIcon}
            width={150}
            align="center"
            title="Status"
            category="RTE (CPU)"
          />
          <Column
            key="rteBenchmarks.cpu.time"
            dataKey="rteBenchmarks.cpu.time"
            cellRenderer={({ cellData }) => displayNumber(cellData, { precision: 2, unit: 'ms' })}
            width={150}
            align="center"
            title="Time"
            category="RTE (CPU)"
            sortable
          />
          <Column
            key="rteBenchmarks.cpu.createdAt"
            dataKey="rteBenchmarks.cpu.createdAt"
            cellRenderer={({ cellData }) => (cellData ? dayjs(cellData).format('L') : '')}
            width={150}
            align="center"
            title="Date"
            category="RTE (CPU)"
            sortable
          />
          <Column
            key="rteBenchmarks.gpu.status"
            dataKey="rteBenchmarks.gpu.status"
            cellRenderer={StatusIcon}
            width={150}
            align="center"
            title="Status"
            category="RTE (GPU)"
            sortable
          />
          <Column
            key="rteBenchmarks.gpu.time"
            dataKey="rteBenchmarks.gpu.time"
            cellRenderer={({ cellData }) => displayNumber(cellData, { precision: 2, unit: 'ms' })}
            width={150}
            align="center"
            title="Time"
            category="RTE (GPU)"
            sortable
          />
          <Column
            key="rteBenchmarks.gpu.createdAt"
            dataKey="rteBenchmarks.gpu.createdAt"
            cellRenderer={({ cellData }) => (cellData ? dayjs(cellData).format('L') : '')}
            width={150}
            align="center"
            title="Date"
            category="RTE (GPU)"
            sortable
          />
          <Column
            key="compilationBenchmarks.cpu.status"
            dataKey="compilationBenchmarks.cpu.status"
            cellRenderer={StatusIcon}
            width={150}
            align="center"
            title="Status"
            category="Compilation (CPU)"
          />
          <Column
            key="compilationBenchmarks.cpu.time"
            dataKey="compilationBenchmarks.cpu.time"
            cellRenderer={({ cellData }) => displayNumber(cellData, { precision: 2, unit: 's' })}
            width={100}
            align="center"
            title="Time"
            category="Compilation (CPU)"
            sortable
          />
          <Column
            key="compilationBenchmarks.cpu.createdAt"
            dataKey="compilationBenchmarks.cpu.createdAt"
            cellRenderer={({ cellData }) => (cellData ? dayjs(cellData).format('L') : '')}
            width={100}
            align="center"
            title="Date"
            category="Compilation (CPU)"
            sortable
          />
          <Column
            key="compilationBenchmarks.gpu.status"
            dataKey="compilationBenchmarks.gpu.status"
            cellRenderer={StatusIcon}
            width={150}
            align="center"
            title="Status"
            category="Compilation (GPU)"
          />
          <Column
            key="compilationBenchmarks.gpu.time"
            dataKey="compilationBenchmarks.gpu.time"
            cellRenderer={({ cellData }) => displayNumber(cellData, { precision: 2, unit: 's' })}
            width={150}
            align="center"
            title="Time"
            category="Compilation (GPU)"
            sortable
          />
          <Column
            key="compilationBenchmarks.gpu.createdAt"
            dataKey="compilationBenchmarks.gpu.createdAt"
            cellRenderer={({ cellData }) => (cellData ? dayjs(cellData).format('L') : '')}
            width={150}
            align="center"
            title="Date"
            category="Compilation (GPU)"
            sortable
          />
        </Table>
      )}
    </AutoResizer>
  );
};
