export type ModelListItemBenchmark = {
  time: number | null;
  createdAt: string;
};

type Model = {
  id: number;
  name: string;
  category: string;
  onnx: string;
  internal: string;
  securityBits: number | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelListItem = Model & {
  compilationBenchmarks: {
    [key: string]: ModelListItemBenchmark;
  };
  inferenceBenchmarks: {
    [key: string]: ModelListItemBenchmark;
  };
};

export type ModelListApiResponse = { results: ModelListItem[] };

export type ModelApiResponse = Model & {
  compilationBenchmarks: any[];
  inferenceBenchmarks: any[];
};

export const getModels = async () => {
  const results = await fetch(`https://benchmarks-api.zama.ai/model`);
  const json: ModelListApiResponse = await results.json();
  return json;
};

export const getModel = async (id: number) => {
  const results = await fetch(`https://benchmarks-api.zama.ai/model/${id}`);
  const json: ModelApiResponse = await results.json();
  return json;
};
