import React from 'react';

import './BenchmarkFilters.css';

export type BenchmarkFiltersProps = {
  search: string;
  setSearch: (search: string) => void;
};

export const BenchmarkFilters = ({ search, setSearch }: BenchmarkFiltersProps) => {
  return (
    <div className="BenchmarkFilters">
      <div className="BenchmarkFilters__line">
        <label htmlFor="table-search">Search:</label>{' '}
        <input id="table-search" type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
      </div>
    </div>
  );
};
