import React, { ReactElement, ReactNode } from 'react';
import BaseTable, { CallOrReturn, ColumnShape } from 'react-base-table';

import './BenchmarkListHeader.css';
import { BenchmarkTree } from './BenchmarkTable';

type HeaderRenderer = CallOrReturn<
  React.ReactNode,
  {
    cells: React.ReactNode[];
    columns: ColumnShape<BenchmarkTree>[];
    headerIndex: number;
  }
>;

export const BenchmarkListHeader: HeaderRenderer = ({ columns, cells, headerIndex }) => {
  if (headerIndex === 1) return cells;

  let groupCells: ReactNode[] = [];
  let width = 0;
  columns.forEach((column, columnIndex) => {
    if (column[BaseTable.PlaceholderKey]) {
      groupCells.push(cells[columnIndex]);
      return;
    }
    const category = column.category;
    const nextColumn = columns[columnIndex + 1];
    const currentCell = cells[columnIndex];
    if (
      !currentCell ||
      typeof currentCell === 'string' ||
      typeof currentCell === 'number' ||
      typeof currentCell === 'boolean'
    )
      return;
    const cell = currentCell as ReactElement;
    width += cell.props.style.width;
    if (columnIndex === columns.length - 1 || nextColumn.category !== category) {
      groupCells.push(
        <div
          key={`header-group-cell-${category}`}
          className="BenchmarksListHeader__categoryHeader"
          style={{ ...cell.props.style, width, textAlign: 'center' }}
        >
          {category}
        </div>
      );

      width = 0;
    }
  });
  return groupCells;
};
