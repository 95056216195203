import React from 'react';
import { Link } from 'react-router-dom';
import { ColumnShape } from 'react-base-table';
import { BenchmarkTree } from './BenchmarkTable';

import './NameCell.css';

export const NameCell: ColumnShape<BenchmarkTree[]>['cellRenderer'] = ({ cellData, rowData }: any) => {
  if (typeof rowData.id === 'number')
    return (
      <Link to={`/benchmark/${rowData.id}`} className="NameCell" title={cellData}>
        {cellData}
      </Link>
    );
  return (
    <span className="NameCell" title={cellData}>
      {cellData}
    </span>
  );
};
