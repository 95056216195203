import React, { ReactNode } from 'react';
import { ColumnShape } from 'react-base-table';

import './StatusIcon.css';

const statusIcons: { [key: string]: ReactNode } = {
  Success: <div className="StatusIcon__successIcon"></div>,
  SuccessButNoFHEParameters: <div className="StatusIcon__partialSuccessIcon"></div>,
  Failure: <div className="StatusIcon__failIcon"></div>,
};

const defaultStatusIcon = <div className="StatusIcon__defaultStatusIcon"></div>;

export const StatusIcon: ColumnShape['cellRenderer'] = ({ cellData }) => {
  if (cellData === undefined) return null;
  return statusIcons[cellData] || defaultStatusIcon;
};
