import dayjs from 'dayjs';
import React from 'react';
import { ModelApiResponse } from '../../api';
import { PublicIcon } from '../PublicIcon';

import './BenchmarkSummary.css';

export type BenchmarkSummaryProps = {
  model: ModelApiResponse;
};

export const BenchmarkSummary = ({ model }: BenchmarkSummaryProps) => {
  return (
    <>
      <h2 className="Benchmark__name">{model.name}</h2>
      <h3 className="Benchmark__category">{model.category}</h3>
      <p className="Benchmark__date">
        <em>
          Created on {dayjs(model.createdAt).format('L')} - Updated on {dayjs(model.updatedAt).format('L')}
        </em>
      </p>
      <p className="BenchmarkSummary__line">
        <b className="BenchmarkSummary__label">Internal:</b> <PublicIcon internal={model.internal} />
      </p>
    </>
  );
};
