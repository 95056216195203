import React, { useEffect, useState } from 'react';
import fp from 'lodash/fp';

import 'react-base-table/styles.css';
import { BenchmarkTable, BenchmarkTree } from '../BenchmarkTable';

import './BenchmarkList.css';
import { BenchmarkFilters } from '../BenchmarkFilters';
import { ModelListItem, getModels } from '../../api';
import { Loading } from '../Loading';

const filterData = (search: string, rawData: ModelListItem[]) => {
  const reSearch = new RegExp(search, 'gi');
  return rawData.filter((row) => {
    return Object.keys(row).some((k) => {
      const key = k as keyof ModelListItem;
      const text = row[key];
      return text && typeof text === 'string' && reSearch.test(text);
    });
  });
};

const categorize = (data: ModelListItem[]) => {
  const categories: BenchmarkTree[] = [];
  data.forEach((row) => {
    let category = categories[categories.length - 1] || {};
    if (category.name !== row.category) {
      category = {
        id: `${row.category}-${row.name}`,
        name: row.category,
        children: [],
      };
      categories.push(category);
    }
    category.children.push(row);
  });
  return categories;
};

export const BenchmarkList = () => {
  const [search, setSearch] = useState<string>('');
  const [data, setData] = useState<ModelListItem[] | null>(null);
  const [treeData, setTreeData] = useState<BenchmarkTree[] | null>(null);
  const [sortBy, setSortBy] = useState<{ key: React.Key; order: 'asc' | 'desc' }>({ key: 'name', order: 'asc' });

  const refreshData = () => {
    const orderedData = fp.orderBy(sortBy.key, sortBy.order, data);
    setTreeData(categorize(filterData(search, orderedData)));
  };

  const fetchData = () => {
    getModels().then((response) => {
      setData(response.results);
    });
  };

  useEffect(refreshData, [data, search, sortBy]);

  useEffect(fetchData, []);

  return (
    <div>
      {!data && (
        <div className="BenchmarkList__loading">
          <Loading size="large" />
        </div>
      )}
      {!!data && !!treeData && (
        <>
          <BenchmarkFilters search={search} setSearch={setSearch} />
          <div className="BenchmarkList__container">
            <BenchmarkTable data={treeData} sortBy={sortBy} onColumnSort={setSortBy} />
          </div>
        </>
      )}
    </div>
  );
};
